
import React from 'react';
import { observer } from "mobx-react-lite";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Snackbar from '@mui/material/Snackbar';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import useSmUp from './../hooks/useSmUp';
import LocationSearch from './LocationSearch';
import useStores from '../stores/useStores';
import CloseIcon from '@mui/icons-material/Close';

const FindConsultationTabPanelStyled = styled('div')(({ theme }) => ({
    marginTop: '1rem',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center'
}));

const SearchTypeListStyled = styled('div')(({ theme }) => ({
    [`${theme.breakpoints.up('sm')}`]: {        
        display: 'flex'
    }    
}));

const ChipsTypeListStyled = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: "center",
    marginLeft: '1rem'
}));

const IconButtonStyled = styled((props) => <IconButton disableRipple {...props} />)(({ theme }) => ({
    width: '100%',
    marginBottom: '1rem',
    backgroundColor: '#19BFB7',
    borderRadius: '.5rem',    
    height: '2.5rem',
    ':disabled': {
        opacity: '.5',
        color: '#19BFB7',
        backgroundColor: '#19BFB7'
    },
    [`${theme.breakpoints.up('sm')}`]: {        
        width: '4rem',
        marginBottom: 0,
        height: '3.5rem',
    }
}));

const SearchIconStyled = styled((props) => <SearchIcon {...props} />)(({ theme }) => ({
    fill: 'white',
    fontSize: '2rem'
}));

const FindConsultationTabPanel = observer(props => {
    const [specialities, setSpecialities] = React.useState([]);
    const [alertMessage, setAlertMessage] = React.useState('');

    const matches = useSmUp();
    const theme = useTheme();

    const { commonStore } = useStores();

    React.useEffect(() => {
        (async() => {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };

            setSpecialities(await ((
                await fetch(
                    `${process.env.REACT_APP_CONSULTME_API_URL}/specialities`, requestOptions)
                ).json()
            ));
        })();
    }, []);

    const handleSearch = () => {
        const {
            latitude,
            longitude,
            pinCode,
        } = commonStore;

        if(isEmpty(pinCode)) {
            commonStore.openFindConsultationAlert = true;
            setAlertMessage('Please select Postal code!.')

            return;
        }else {
            commonStore.openFindConsultationAlert = false;
        }

        if(isEmpty(commonStore.specialty)) {
            commonStore.openFindConsultationAlert = true;
            setAlertMessage('Please select specialty!.')
            return;
        }else {
            commonStore.openFindConsultationAlert = false;
        }

        const queryParams = `redirect=true&latitude=${latitude}`
            + `&longitude=${longitude}`
            + `&pinCode=${pinCode}`
            + `&specialty=${commonStore.specialty}`
            + `&path=doctors`;
        window.location = `${process.env.REACT_APP_CONSULTME_URL}?${queryParams}`;
    }

    return (
        <FindConsultationTabPanelStyled>
            <Snackbar
                open={commonStore.openFindConsultationAlert}
                autoHideDuration={6000} onClose={() => {
                    commonStore.openFindConsultationAlert = false;
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert severity="error" variant="filled" action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            commonStore.openFindConsultationAlert = false;
                        }}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                } >
                    <AlertTitle>{alertMessage}</AlertTitle>
                </Alert>
            </Snackbar>
            
            <LocationSearch sx={{
                width: '100%',
                [`${theme.breakpoints.up('sm')}`]: {
                    width: 'calc(100% - 5rem)',
                    marginRight: '1rem',
                }
            }}/>
            <IconButtonStyled type="submit"
                sx={{ p: '.5rem 1rem' }}
                aria-label="search"
                color="primary"
                onClick={() => handleSearch()}
                disabled={isEmpty(commonStore.pinCode)}
            >
                {matches && <SearchIconStyled />}
                {!matches && 
                    <Typography sx={{color: '#fff'}}>Search</Typography>
                }
            </IconButtonStyled>

            <SearchTypeListStyled sx={(theme) => ({
                gap: '.5rem',                
                [`${theme.breakpoints.up('sm')}`]: {
                    flexFlow: 'column',
                    gap: '1rem',
                }
            })}>
                <Typography variant='body1' gutterBottom>You may be looking for:</Typography>
                <ChipsTypeListStyled sx={(theme) => ({
                    marginLeft: 0
                })}>
                    <Stack direction="row" spacing={1} sx={(theme) => ({
                        flexWrap: 'wrap',
                        gap: '.5rem',
                        [`${theme.breakpoints.up('sm')}`]: {
                            flexWrap: 'wrap',
                            gap: '.5rem',
                        }
                    })}>
                        {specialities.length !== 0 && specialities.map(speciality => {
                            return (
                                <Chip 
                                    key={speciality.speciality_id}
                                    label={speciality.speciality_name}
                                    onClick = {() => {commonStore.specialty = speciality.speciality_name; handleSearch()}}
                                    variant="outlined"
                                    sx={(theme) => ({
                                        marginLeft: '0 !important',
                                        [`${theme.breakpoints.up('sm')}`]: {
                                            marginLeft: '.5rem !important',
                                        }
                                    })}
                                />
                            )}
                        )}
                    </Stack>     
                </ChipsTypeListStyled>
            </SearchTypeListStyled>
        </FindConsultationTabPanelStyled>
    );
});

export default FindConsultationTabPanel;