import { useState } from 'react';

import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import TermsAndConditions from './TermsAndConditions';
import FaqsAndPolicies from './FaqsAndPolicies';
import { styled } from '@mui/material/styles';

import Logo from './Logo';
import PageUnderDevelopment from './PageUnderDevelopment';

const HeaderStyled = styled('header')(({ theme }) => ({
    backgroundColor: '#EBEBEB',
    padding: '.5rem 1.5rem',
    fontWeight: 'bold',
    '& svg': {
        cursor: 'pointer',
    }
}));

const Header = props => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openP, setOpenP] = useState(false);
    const [openTermsAndConditionDialog, setOpenTermsAndConditionDialog] = useState(false);
    const [openFaqsAndPolicies, setOpenFaqsAndPolicies] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.target);
    };

    const handleDialogClose = () => {
        setOpenTermsAndConditionDialog(false);
    }

    const handleFaqsAndPoliciesClose = () =>{
        setOpenFaqsAndPolicies(false);
    }

    const loadContactUs = () => {
        window.location.href = "mailto:info@mediradar.com?subject=Contact us&body=Please leave your name, phone number and brief enquiry and our team will get back to you at the earliest opportunity.";
    }


    const open = Boolean(anchorEl);

    return (
        <HeaderStyled>
            <Box sx={(theme) => ({
                margin: 'auto',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                [`${theme.breakpoints.up('sm')}`]: {
                    maxWidth: '85rem'
                }
            })}>
                <Logo />
                <Box  onClick={handleClick} sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem'
                }}>
                    <Typography sx={{
                        fontWeight: 'bold',
                        cursor: 'pointer'
                    }}>
                        Login
                    </Typography>
                </Box>
                <Popper
                    open={open}
                    anchorEl={anchorEl}
                    placement="bottom-end"
                    modifiers={[
                        {
                            name: 'offset',
                            options: {
                                offset: [10, 28],
                            },
                        },
                    ]}
                >
                    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                        <Fade in={open} timeout={1000}>
                            <Paper elevation={5} sx={{
                                padding: '.5rem',
                                minWidth: '10.3125rem',
                                '& .MuiList-padding': {
                                    paddingTop: '0',
                                    paddingBottom: '0'
                                }
                            }}>
                                <List>
                                    <ListItemButton disablePadding onClick={() => window.location = `${process.env.REACT_APP_SCANME_URL}/locations?showLogin=true`}>
                                        <ListItemText primary="As a customer" />
                                    </ListItemButton>
                                    <ListItemButton disablePadding onClick={() => window.location = `${process.env.REACT_APP_CONSULTME_PRACTITIONER_URL}`}>
                                        <ListItemText primary="As a practitioner" />
                                    </ListItemButton>
                                    <ListItemButton disablePadding onClick={() => window.location = `${process.env.REACT_APP_PROVIDER_URL}`}>
                                        <ListItemText primary="As a provider" />
                                    </ListItemButton>
                                    <ListItemButton disablePadding onClick={() => window.location = `${process.env.REACT_APP_CONSULTME_URL}`}>
                                        <ListItemText primary="As a patient" />
                                    </ListItemButton>
                                    <Divider />
                                    <ListItemButton onClick={() => {
                                        setOpenTermsAndConditionDialog(true);
                                    }} >
                                        <ListItemText primary="Terms And Conditions" key="TermsAndConditions" />
                                    </ListItemButton>
                                    <ListItemButton  onClick={() => {
                                        setOpenFaqsAndPolicies(true);
                                    }} disablePadding>
                                        <ListItemText primary="FAQ and policies" />
                                    </ListItemButton>
                                    <ListItemButton disablePadding onClick={() => {
                                        loadContactUs();
                                    }} >
                                        <ListItemText primary="Contact Us" />
                                    </ListItemButton>
                                </List>
                            </Paper>
                        </Fade>
                    </ClickAwayListener>
                </Popper>
            </Box>
            <PageUnderDevelopment
                title="Practitioners"
                open={openP}
                handleClose={() => setOpenP(false)}
            />
            <TermsAndConditions open={openTermsAndConditionDialog} handleClose={handleDialogClose} />
            <FaqsAndPolicies open={openFaqsAndPolicies} handleClose={handleFaqsAndPoliciesClose} />

            
        </HeaderStyled>
    );
};

export default Header;
