import React from 'react';
import PropTypes from 'prop-types';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import FindConsultationTabPanel from './FindConsultationTabPanel';
import FindScansTabPanel from './FindScansTabPanel';
import useSmUp from './../hooks/useSmUp';
import { observer } from "mobx-react-lite"

const PageNavigationTabsStyled = styled('div')(({ theme }) => ({
    padding: '1rem',
    textTransform: 'none'
}));

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        '&.Mui-selected': {
            color: '#000',
            fontWeight: 'bold'
        },
        '&.Mui-focusVisible': {
            backgroundColor: '#19BFB7',
            fontWeight: 'bold'
        },
        '&:hover': {
            color: '#000',
            fontWeight: 'bold',
            opacity: 1,
        },
    }),
);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    {children}
                </Box>
            )}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

const PageNavigationTabs = observer(props => {
    const [value, setValue] = React.useState(0);
    const matches = useSmUp();
    const newValue = matches ? value : 0;

    const handleChange = (event, val) => {
        if (matches) {
            setValue(val);
        } else {
            setValue(event.target.value);
        }
    };

    return (
        <PageNavigationTabsStyled>
            <Box sx={{ width: '100%' }}>
                <Box>
                    {matches ? (
                         <Tabs value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            sx={{ borderBottom: 1, borderColor: '#C7E0DF' }}
                        >
                            <StyledTab label="Find a scan" {...a11yProps(1)} />
                            <StyledTab label="Find a consultation" {...a11yProps(0)} sx={{width: '17rem'}}/>
                        </Tabs>
                    ) : (
                        <Tabs value={newValue}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            sx={{ borderBottom: 1, borderColor: '#C7E0DF' }}
                        >
                            <StyledTab value={0} sx={{p: 0}} label={
                                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                    <Select
                                        value={value}
                                        onChange={handleChange}
                                        sx={{
                                            p: 0,
                                            select: {
                                                color: 'red'
                                            },
                                            ':before': {
                                                border: 'none',
                                                borderBottom: 'none',
                                            },
                                            ':before:focus': {
                                                border: 'none',
                                                borderBottom: 'none',
                                            },
                                            ':before:active': {
                                                border: 'none',
                                                borderBottom: 'none',
                                            },
                                            ':after': {
                                                border: 'none',
                                                borderBottom: 'none',
                                            },
                                            ':after:focus': {
                                                border: 'none',
                                                borderBottom: 'none',
                                            },
                                            ':after:active': {
                                                border: 'none',
                                                borderBottom: 'none',
                                            },
                                            '> div:focus': {
                                                backgroundColor: 'transparent'
                                            }
                                        }}
                                    >
                                        <MenuItem value={0}>Find a scan</MenuItem>
                                        <MenuItem value={1}>Find a consultation</MenuItem>
                                    </Select>
                                </FormControl>
                            }/>
                        </Tabs>
                    )}
                    <TabPanel value={value} index={0}>
                        <FindScansTabPanel></FindScansTabPanel>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <FindConsultationTabPanel ></FindConsultationTabPanel>
                    </TabPanel>
                </Box>
            </Box>
        </PageNavigationTabsStyled>
    );
});

export default PageNavigationTabs;